import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locale_de from './de.json'
import locale_fr from './fr.json'
import locale_tr from './tr.json'
import locale_ar from './ar.json'
import locale_en from './en.json'
import locale_nl from './nl.json'

const resources = {
    en: {
        translation: locale_en
    },

    fr: {
        translation: locale_fr
    },
    ar: {
        translation: locale_ar
    },
    tr: {
        translation: locale_tr
    },
    de: {
        translation: locale_de
    },
    nl: {
        translation: locale_nl
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;