import logo from './logo.svg';
import './App.css';
import HomePage from "./pages/Home-page/home-page";
import translationHelperInstance from "./locales/translationHelper"
import {RouterProvider} from "react-router-dom";
import router from "./route/route";

function App() {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
