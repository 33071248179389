import axios from 'axios';
import FormData from "form-data";

let token = '';
const getToken = () => {
    if (token === '') {
        token = localStorage.getItem('token');
        return token;
    } else {
        return token;
    }
};

export const getAllLanguages = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    const response = await fetch(
        'https://beauty-station.kroppalab.com/dev/api/v1/languages',
        requestOptions
    );
    const data = await response.json();
    return data;
};

export const getLanguageData = async (countyCode)=>{
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    const formdata = new FormData();
    formdata.append("lang_code", countyCode);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    const response = await fetch("https://beauty-station.kroppalab.com/dev/api/v1/languages/translations", requestOptions);
    const data = await response.json();
    return data
}
export const formSave = async (countyCode,name,email,phone_code,phone,gender)=>{
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const formdata = new FormData();
    formdata.append("lang_code", countyCode);
    formdata.append("name_surname", name);
    formdata.append("email", email);
    formdata.append("phone_code", phone_code);
    formdata.append("phone", phone);
    formdata.append("gender", gender);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch("https://beauty-station.kroppalab.com/dev/api/v1/form/save", requestOptions)
    const data = await response.json();
    return data
}
export const switchSpark = async (
    gender,
    language,
    unique_key,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    const response = await fetch(
        `http://localhost:8000/?` +
        new URLSearchParams({
            action: "switch_spark",
            gender: gender,
            language: language,
            unique_key: unique_key,
        }),
        requestOptions
    );
    const data = await response.json();
    return data;
};
export const getImage = async (
) => {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    const response = await fetch(
        `http://localhost:8000/?` +
        new URLSearchParams({
            action: "getimage",
        }),
        requestOptions
    );
    const data = await response.text();
    return data;
};
export const retakeImage = async (
) => {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    const response = await fetch(
        `http://localhost:8000/?` +
        new URLSearchParams({
            action: "retake",
        }),
        requestOptions
    );
    const data = await response.text();
    return data;
};
export const sendImage = async (unique_key,file)=>{
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    const formdata = new FormData();
    formdata.append("unique_key", unique_key);
    formdata.append("image_url", file);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const response = await  fetch("https://beauty-station.kroppalab.com/dev/api/v1/form/image/save", requestOptions)
    const data = await response.json();
    return data;
}

export const getStatus = async (
) => {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    const response = await fetch(
        `http://localhost:8000/?` +
        new URLSearchParams({
            action: "getstatus",
        }),
        requestOptions
    );
    const data = await response.text();
    return data;
};