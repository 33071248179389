import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logos/header-logo.svg";
import man from "../../images/genders/man.png";
import woman from "../../images/genders/woman.png";
import selectedMan from "../../images/genders/selected-man.png";
import selectedWoman from "../../images/genders/selected-woman.png";
import "./login-page.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { formSave, switchSpark } from "../../API/helper";
import Lottie from "react-lottie-player";
import animation from "../../images/animation.json";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [position, setPosition] = useState("center");
  const [name, setName] = useState("");
  const [counter, setCounter] = useState(60);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const toast = useRef(null);
  const mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const { state } = useLocation();
  const [loaderDisplay, setLoaderDisplay] = useState("none");
  const [nameIsValid, setNameIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [genderIsValid, setGenderIsValid] = useState(false);
  const [time, setTime] = useState();
  const isValid = (error) => {
    toast.current.show({ severity: "error", summary: "Error!", detail: error });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCount) => prevCount - 1);
    }, 1000);
    if (counter === 0) {
      navigate("/");
    }
    return () => clearInterval(intervalId);
  }, [counter]);

  const dialogFuncMap = {
    displayConfirm: setDisplayConfirm,
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setChecked(false);
  };
  const getSpark = async (gender, language, unique_key) => {
    const spark = await switchSpark(gender, language, unique_key);
    return spark;
  };
  const handleSubmit = () => {
    const selectedPhone = "+" + phone;
    let timeout;
    formSave(
     state.countryCode,
      name,
      email,
      "+90",
      selectedPhone,
      selectedGender
    ).then((resp) => {
      if (resp.status) {
        setLoaderDisplay("flex");
        const gender = resp.form.gender === "man" ? "m" : "f";
        getSpark(gender, resp.form.lang_code, resp.form.unique_key);
        timeout = setTimeout(() => {
          setLoaderDisplay("none");
          navigate("/result-page", {
            state: { unique_key: resp.form.unique_key ,language:state.countryCode},
          });
        }, 10000);
      }
    });
  };
  const renderFooter = (name) => {
    return (
      <div className="button-wrapper">
        <Button
          label={t("permission_button")}
          onClick={() => {
            onHide(name);
            setChecked(true);
            setCounter(60);
          }}
          className="p-button-secondary confirm-button"
        />
      </div>
    );
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  useEffect(() => {
    if (selectedGender !== null) {
      setGenderIsValid(false);
    }
  }, [selectedGender]);
  useEffect(() => {
    setTime(30);
  }, []);
  const handleClick = () => {
    if (name.length < 5) {
      isValid(t("name_error"));
      setNameIsValid(true);
    } else if (!email.match(mailFormat)) {
      isValid(t("email_error"));
      setNameIsValid(false);
      setEmailIsValid(true);
    } else if (phone.length < 10) {
      isValid(t("phone_error"));
      setEmailIsValid(false);
      setPhoneIsValid(true);
    } else if (!selectedGender) {
      isValid(t("gender_error"));
      setPhoneIsValid(false);
      setGenderIsValid(true);
    } else if (!checked) {
      isValid("Lütfen iletişim izinleri kullanımını kabul edin");
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      <div className="result-wrapper" style={{ display: loaderDisplay }}>
        <Lottie
          loop
          animationData={animation}
          play
          style={{ width: 1000, height: 1000 }}
        />
      </div>
      <div
        className="login-wrapper"
        style={{ display: loaderDisplay === "flex" ? "none" : "flex" }}
      >
        <Toast ref={toast} />
        <img
          alt="natural-clinic-logo"
          width={351}
          height={170}
          src={logo}
          className="menu-logo-img"
        />
        <div className="span-wrapper">
          <span>{t("intro_text")}</span>
        </div>
        <div className="input-wrapper">
          <InputText
            type="text"
            className={"login-input " + (nameIsValid ? "p-invalid" : "")}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setTime(30);
              setCounter(60);
            }}
            placeholder={t("username")}
          />
          <InputText
            type="email"
            className={"login-input " + (emailIsValid ? "p-invalid" : "")}
            value={email}
            onChange={(e) => {
              setCounter(60);
              setEmail(e.target.value);
            }}
            placeholder={t("email")}
          />

          <div className="phone-wrapper">
            <PhoneInput
              country={state.countryCode==="en" ? "gb" : state.countryCode==="ar" ? "sa": state.countryCode}
              style={
                phoneIsValid
                  ? { border: "0.5px solid red" }
                  : { border: "1px solid white" }
              }
              value={phone}
              onChange={(phone) => {
                setCounter(60);
                setPhone(phone);
              }}
              containerClass="custom-container-class"
              inputClass="custom-input-class"
              dropdownClass="custom-dropdown-class"
              searchClass="custom-search-class"
              buttonClass="custom-button-class"
            />
          </div>
        </div>
        <div className="image-wrapper">
          {" "}
          {selectedGender === "woman" ? (
            <img
              alt="natural-clinic-logo"
              width={250}
              height={250}
              src={selectedWoman}
              className="menu-logo-img"
            />
          ) : (
            <img
              alt="natural-clinic-logo"
              width={250}
              height={250}
              src={woman}
              onClick={() => {
                setCounter(60);
                setSelectedGender("woman");
              }}
              className={
                "menu-logo-img " + (genderIsValid ? "input-error" : "")
              }
            />
          )}
          {selectedGender === "man" ? (
            <img
              alt="natural-clinic-logo"
              width={250}
              height={250}
              src={selectedMan}
              className="menu-logo-img"
            />
          ) : (
            <img
              alt="natural-clinic-logo"
              width={250}
              height={250}
              src={man}
              onClick={() => {
                setCounter(60);
                setSelectedGender("man");
              }}
              className={
                "menu-logo-img " + (genderIsValid ? "input-error" : "")
              }
            />
          )}{" "}
        </div>
        <div className="field-checkbox checkbox-wrapper">
          <Checkbox
            className="login-checkbox"
            inputId="binary"
            checked={checked}
            onChange={() => {
              setCounter(60);
              onClick("displayConfirm");
            }}
          />{" "}
          <label htmlFor="binary" className="login-label">
            {t("communication_permissions")}
          </label>
        </div>
        <div className="buttons-wrapper">
          <Button
              label={t('back_button')}
            onClick={() => {
              navigate("/");
            }}
            className="login-buttons"
          />
          <Button
            label={t('next_button')}
            onClick={() => handleClick()}
            className="login-buttons"
          />
        </div>
        <Dialog
          visible={displayConfirm}
          style={{ width: "70vw", height: "40vh" }}
          footer={renderFooter("displayConfirm")}
          onHide={() => onHide("displayConfirm")}
        >
          {" "}
          <p style={{ fontSize: 30, textAlign: "center" }}>
            {t("permissions_info")}
          </p>
        </Dialog>
      </div>
    </div>
  );
};

export default LoginPage;
