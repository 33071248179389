import React, {useEffect, useRef, useState} from 'react';
import './result-page.css'
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import logo from '../../images/logos/first-page-logo.svg'
import headerLogo from '../../images/logos/result-logo.svg'


import {useLocation, useNavigate} from "react-router-dom";
import {getImage, getLanguageData, getStatus, retakeImage, sendImage} from "../../API/helper";
import Lottie from "react-lottie-player";
import animation from "../../images/animation.json";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import ReactCountdownClock from "react-countdown-clock"


function ResultPage(props) {
    const { state } = useLocation();
    const toast = useRef(null);
    const navigate = useNavigate()
    const [background,setBackground] = useState("")
    const [loaderDisplay,setLoaderDisplay]=useState('none')
    const [file,setFile]=useState(null)
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(false)
    const [uniqueKey,setUniqueKey]=useState("")
    const [time,setTime] = useState(30)

    let timeout;
    const getAllStatus = () => {
        i18n.changeLanguage(state.language);
        getStatus().then((resp)=>{
            if(resp==="keep"){
                const timeout= setTimeout(()=>{
                    getStatus()
                    clearTimeout(timeout)
                },2000)
            }else if(resp==="goidle"){
                navigate('/')
            }
        })
    }
    const getAllImage = () => {
        getImage().then((resp)=>{
            if(resp==="noimage"){
                const timeout= setTimeout(()=>{
                    getAllImage()
                    clearTimeout(timeout)
                    navigate(0)
                },3000)
            }else{
                setBackground(resp+`?timestamp=${Date.now()}`)
                fetch(resp)
                    .then(async function(response) {
                        const blob = await response.blob()
                        const file = new File([blob], 'image_url', { type: 'image/png' });
                        setFile(file)
                    })
            }
        })
    }
    const handleRetakeQuick = async () =>{
        await retakeImage()
        setLoaderDisplay("flex")
        const timeout = setTimeout(()=>{
            setLoaderDisplay("none")
            navigate(0)
            clearTimeout(timeout)
        }, 10000);
    }

    const handleSendQuick = async ()=>{
        setLoading(true)
        sendImage(uniqueKey,file).then((res)=>{
            if(res.status){
                toast.current.show({severity:'success',summary: 'Success', detail:t('send_success'), life: 2000});
                const timeout = setTimeout(()=>{
                    setLoading(false)
                    navigate('/')
                    clearTimeout(timeout)
                }, 2000);
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:t('send_error'), life: 5000});
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        getAllImage()
        getAllStatus()
        setUniqueKey(state.unique_key === null ? "":state.unique_key)
    },[])


    return (
        <div>
            <Toast ref={toast}/>
            <div className="result-wrapper" style={{display:loaderDisplay}}>
                <Lottie loop animationData={animation} play style={{ width: 1000, height: 1000 }} />
            </div>
            <div className="spinner-wrapper">
                {loaderDisplay==="flex" ? null :
                    <ReactCountdownClock seconds={time}
                                         color="white"
                                         alpha={0.9}
                                         size={150}
                                         onComplete={()=>{navigate('/')}
                                         }/>}

            </div>
            <div className="result-wrapper" style={{display:loaderDisplay==="flex" ? "none" : "flex"}}>
                <div className="card-wrapper">
                    <div className="content-wrapper" style={{backgroundImage:`url(${background})`}}>
                        <div className="overlay-top">
                            <img
                                alt="natural-clinic-logo"
                                src={headerLogo}
                            />
                        </div>
                        <div className="overlay-bottom">
                            <div className="bottom-logo">
                                <img
                                    alt="natural-clinic-logo"
                                    src={logo}
                                    className="bottom-logo-img"
                                />
                            </div>
                            <div className="bottom-info">
                                <span>natural.clinic</span>
                                <span>+90 212 919 05 55</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="result-button-wrapper">
                    <Button className="send-button" loading={loading} label={t('send_button')} onClick={handleSendQuick} />
                    <Button className="picture-button" label={t('retake_button')} onClick={()=>{handleRetakeQuick()}} />
                </div>
            </div>
        </div>
    );
}

export default ResultPage;