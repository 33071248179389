import { createBrowserRouter } from 'react-router-dom';
import HomePage from "../pages/Home-page/home-page";
import LoginPage from "../pages/Login-page/login-page";
import ResultPage from "../pages/Result-page/result-page";


const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/login-page',
        element: <LoginPage />,
    },
    {
        path: '/result-page',
        element: <ResultPage />,
    },
]);

export default router;
