import React, {useEffect, useState} from 'react';
import logo from '../../images/logos/first-page-logo.svg'
import './home-page.css'
import {useNavigate} from "react-router-dom";
import {getAllLanguages, getLanguageData} from "../../API/helper";
import {useTranslation} from "react-i18next";
import deSVG from '../../images/flags/de.svg'
const HomePage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [languages,setLanguages]=useState([])
    const getAlLanguages = async ()=>{
        const language = await getAllLanguages()
        setLanguages(language.langs)
    }
    const handleSubmit = (countryCode) =>{
        i18n.changeLanguage(countryCode);
        getLanguageData(countryCode)
            .then((resp)=>{
                navigate('/login-page',{state:{countryCode:countryCode}})
            })
    }
    useEffect(()=>{
        getAlLanguages()
    },[])
    return (
        <div className="home-wrapper">
                <img
                    alt="natural-clinic-logo"
                    width={628.1}
                    height={304}
                    src={logo}
                />
                <span className="try-span">Try now</span>
                <div className="languages-wrapper">
                    {languages?.map((language,key)=><div key={key} className="flag-wrapper" style={{marginLeft:20}}>
                        <img
                            alt="turkish-flag"
                            width={120}
                            height={100}
                            src={language.image}
                            className="menu-logo-img"
                            onClick={()=>handleSubmit(language.code)}
                        />
                        <span className="flag-title">{language.title}</span>
                    </div>)}
                </div>
        </div>
    );
};

export default HomePage;